@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  background: #F7FAFD;
  height: 100vh;
}

html {
  font-family: 'Nunito', sans-serif;
}

.phoneInput .react-tel-input {
  margin-right: 10px;
}

.phoneInput .react-tel-input .flag-dropdown {
  margin-right: 10px;
  height: 35px;
  background: transparent !important;
}

.phoneInput .react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}

.phoneInput .react-tel-input .selected-flag:hover {
  background: transparent !important;
}

.CountrySelectorError .react-tel-input .flag-dropdown {
  border: 2px solid #E7515A !important;
  border-radius: 6px 0 0 6px !important;
}

.CountrySelector .react-tel-input .flag-dropdown {
  border: 2px solid #D8D8D8 !important;
  border-radius: 6px 0 0 6px !important;
}

.phoneInput .react-tel-input:has(.form-control:focus) .flag-dropdown {
  border: 2px solid #13151F !important;
  border-radius: 6px 0 0 6px !important;
}

.CountrySelector .react-tel-input .form-control {
  width: 100% !important;
  border: 2px solid #D8D8D8 !important;
  border-radius: 6px !important;
}

.CountrySelectorError .react-tel-input .form-control {
  width: 100% !important;
  border: 2px solid #E7515A !important;
  border-radius: 6px !important;
}

.phoneInput .react-tel-input .form-control:focus {
  border: 2px solid #13151F !important;
}

.recipient .react-tel-input .form-control {
  background: transparent !important;
}

.preview img {
  height: auto !important;
  max-height: 100% !important;
}

/* .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.react-pdf__Page {
  min-width: 100% !important;
  max-width: 100% !important;
  height: calc(100vh - 190px) !important;
  border: 2px solid black;
}

.react-pdf__Page canvas {
  height: calc(100vh - 194px) !important;
  width: 100% !important;
}

.react-pdf__message {
  color: white;
}

.react-pdf__Page__textContent {
  height: calc(100vh - 190px) !important;
  width: 100% !important;
}

.react-pdf__Page__annotations {
  height: calc(100vh - 190px) !important;
  width: 100% !important;
}

.ant-picker-dropdown {
  inset-block-start: 112px !important;
}

.ant-picker-dropdown-range {
  padding: 0 !important;
}

.ant-picker-range-arrow {
  display: none !important;
}

.ant-picker-range {
  width: 457px !important;
  border-radius: 8px 8px 0 0 !important;
  border: 1px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-picker:hover {
  border: 1px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-picker-panel-container {
  border-radius: 0 0 8px 8px !important;
}

.ant-btn-primary {
  background-color: #38A6DE !important;
}

.ant-picker-panel-container {
  width: 457px !important;
}


.ant-picker-year-panel {
  width: 457px !important;
}

.ant-picker-month-panel {
  width: 457px !important;
}

.ant-picker-body {
  width: 457px !important;
}

.ant-picker-decade-panel{
  width: 457px !important;
}

.infoMsg a {
  color: #38A6DE !important;
  font-weight: 800;
  cursor: pointer;
}

.rdt_TableCell {
  min-width: 170px !important;
}

@media (min-width: 0px) and (max-width: 820px) {
  .ant-picker-panel-container {
    width: 350px !important;
  }

  .ant-picker-year-panel {
    width: 350px !important;
  }

  .ant-picker-month-panel {
    width: 350px !important;
  }

  .ant-picker-range {
    width: 350px !important;
  }

  .ant-picker-body {
    width: 350px !important;
  }

  .ant-picker-decade-panel{
    width: 350px !important;
  }

  .ant-picker-date-panel{
    width: 350px !important;
  }

  .ant-picker-date-panel .ant-picker-body .ant-picker-content{
    width: 315px !important;
  }

  .ant-picker-datetime-panel{
    flex-direction: column !important;
  }

  .ant-picker-time-panel-column{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column !important;
  }
}